import { Component, input } from '@angular/core';
import { isNullOrWhitespace as isNullOrEmpty } from '../../utils';

@Component({
    selector: 'nuis-display-textarea',
    standalone: true,
    imports: [],
    template: `
        <div class="flex flex-column gap-1">
            <div class="text-sm text-500">{{ label() }}</div>
            <div>
                @if (!isNullOrEmpty(value())) {
                    <div class="text-sm" [innerText]="value()"></div>
                } @else {
                    <i class="pi pi-minus"></i>
                }
            </div>
        </div>
    `,
})
export class DisplayTextAreaComponent {
    public label = input.required<string>();
    public value = input.required<string | null>();

    protected isNullOrEmpty = isNullOrEmpty;
}
