import { Component, Input } from '@angular/core';
import { Address } from '../../models/address.model';

@Component({
    selector: 'shared-address',
    templateUrl: './address.component.html',
})
export class AddressComponent {
    @Input() isEditing = false;
    @Input() address: Address;
}
