import { DatePipe, NgIf } from '@angular/common';
import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { Audit, AuditAtomic } from '../../models/audit.model';
import { PartnerIdPipe } from '../../pipes/partner-id.pipe';
import { AuditsService } from '../../services/audits.service';
import { TableCellComponent } from '../table-cell/table-cell.component';

@Component({
    selector: 'shared-audits',
    standalone: true,
    imports: [
        NgIf,
        DatePipe,
        TranslatePipe,
        FormsModule,
        TableModule,
        MultiSelectModule,
        TableCellComponent,
        PartnerIdPipe,
    ],
    templateUrl: './audits.component.html',
    styleUrls: ['./audits.component.scss'],
})
export class AuditsComponent implements OnInit {
    private readonly auditsService = inject(AuditsService);
    private readonly translate = inject(TranslateService);
    private readonly destroyRef = inject(DestroyRef);

    @Input({ required: true }) public entityId: string;
    @Input({ required: true }) public entityTypes: string[];

    protected audits: AuditAtomic[];
    protected fields: string[];

    public ngOnInit() {
        this.auditsService
            .getAudits(this.entityId, this.entityTypes)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((audits: Audit[]) => {
                this.audits = this.flattenAudits(audits);
                this.initFields();
            });
    }

    private flattenAudits(audits: Audit[]): AuditAtomic[] {
        const flattened: AuditAtomic[] = [];
        for (const audit of audits) {
            for (const change of audit.changes) {
                flattened.push({
                    date: audit.date,
                    author: audit.author,
                    field: {
                        label: this.translate.instant(`auditField.${change.field}`),
                    },
                    old: change.old,
                    new: change.new,
                });
            }
        }
        return flattened;
    }

    private initFields() {
        const changedFields: Set<string> = new Set();
        for (const audit of this.audits) {
            changedFields.add(audit.field?.label);
        }
        this.fields = [...changedFields];
    }
}
