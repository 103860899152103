<div class="mb-3 flex flex-row justify-content-between align-items-center">
    <span class="text-xl font-medium text-900">{{ 'additionalInsuredPersons' | translate }}</span>

    @if (checkHasPermission('update_contract', partnerId()) && productCategory$ | async) {
        @if (insuredPersons().length < maximumPersons()) {
            <button
                pRipple
                pButton
                type="button"
                icon="pi pi-plus"
                class="p-button-text p-button-success text-xl"
                [pTooltip]="'tooltip.add' | translate"
                tooltipPosition="left"
                (click)="isAddingPerson = true"></button>
        }
    }
</div>
@if (isAddingPerson) {
    <p-dialog
        header="{{ 'addAdditionalInsuredPerson' | translate }}"
        [(visible)]="isAddingPerson"
        showEffect="fade"
        [style]="{ width: '75%' }"
        [closeOnEscape]="false">
        <div class="p-2 pl-3 pr-3 m-0 w-full fill-height surface-ground">
            <p class="line-height-3 m-0">
                <shared-person-editor
                    [person]="addingPerson"
                    [restrictPartner]="partnerId() | partnerIdKey"
                    restrictPermission="update_contract"
                    (personChanged)="onPersonAdded($event)"
                    [showAddress]="false"
                    [isAdding]="true"
                    [restrictions]="insuredPeopleRestrictions()"
                    [partnerId]="partnerId()"
                    [showContacts]="false" />
            </p>
        </div>
    </p-dialog>

    <div class="blurred-layer" (click)="isAddingPerson = false"></div>
}

@if (displayPerson) {
    <p-dialog
        header="{{ 'additionalInsuredPerson' | translate }}"
        [(visible)]="displayPerson"
        showEffect="fade"
        [style]="{ width: '75%' }"
        [closeOnEscape]="false">
        <div class="p-2 pl-3 pr-3 m-0 w-full fill-height surface-ground">
            <p class="line-height-3 m-0">
                <shared-person-editor
                    [person]="editingPerson"
                    [restrictPartner]="partnerId() | partnerIdKey"
                    restrictPermission="update_contract"
                    (personChanged)="onPersonChanged($event)"
                    [showAddress]="false"
                    [partnerId]="partnerId()"
                    [restrictions]="insuredPeopleRestrictions()"
                    [showContacts]="false" />
            </p>
        </div>
    </p-dialog>

    <div class="blurred-layer" (click)="displayPerson = false"></div>
}

<p-table
    [value]="insuredPersons()"
    editMode="row"
    responsiveLayout="scroll"
    selectionMode="single"
    [showCurrentPageReport]="false"
    sortField="dateOfBirth"
    [sortOrder]="1"
    dataKey="internalId"
    styleClass="p-datatable-sm">
    <ng-template pTemplate="header">
        <tr id="table-head">
            <th id="age_icon"></th>
            <th id="firstname">{{ 'firstname' | translate }}</th>
            <th id="lastname">{{ 'lastname' | translate }}</th>
            <th id="dateOfBirth">{{ 'dateOfBirth' | translate }}</th>
            <th id="empty"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-person let-editing="editing" let-ri="rowIndex">
        <tr>
            <td>
                <shared-person-age-icon
                    [dateOfBirth]="person.dateOfBirth"
                    [restrictions]="insuredPeopleRestrictions()"></shared-person-age-icon>
            </td>
            <td (click)="openPersonEditor(ri)">
                <shared-table-cell [value]="person.firstName">
                    {{ person.firstName }}
                </shared-table-cell>
            </td>
            <td (click)="openPersonEditor(ri)">
                <shared-table-cell [value]="person.lastName">
                    {{ person.lastName }}
                </shared-table-cell>
            </td>
            <td (click)="openPersonEditor(ri)">
                <shared-table-cell [value]="person.dateOfBirth">
                    {{ person.dateOfBirth | date: 'dd.MM.yyyy' }}
                </shared-table-cell>
            </td>
            <td>
                <span class="flex flex-row-reverse">
                    <ng-container *hasPermission="'update_contract'; partner: partnerId() | partnerIdKey">
                        @if (!editing) {
                            <button
                                pButton
                                pRipple
                                type="button"
                                icon="pi pi-trash"
                                (click)="remove(ri)"
                                class="p-button-rounded p-button-danger p-button-text"></button>
                        }
                    </ng-container>
                </span>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
        <tr class="text-700">
            <td colspan="5">
                <i class="pi pi-info-circle text-xl mr-2"></i>
                {{ 'noAdditionalInsuredPersonsFound' | translate }}
            </td>
        </tr>
    </ng-template>
</p-table>
