import { inject, Injectable } from '@angular/core';
import { Configuration, HttpClientAdapter } from '@luis/common/shared';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, from, map, mergeMap, Observable, of, startWith } from 'rxjs';
import { DocumentContentDto, DocumentDto, mapDocumentDto } from '../../dtos';
import { FileUploadNameStrategy, FileUploadStatus } from '../../models';
import { HumanizeFilesizePipe } from '../../pipes';
import { findUniqueFileName, getFileParts, megabyte, readFileAsBase64 } from '../../utils';

@Injectable({
    providedIn: 'root',
})
export class DocumentApiService {
    private readonly http = inject(HttpClientAdapter);
    private readonly config = inject(Configuration);
    private readonly translate = inject(TranslateService);
    private readonly humanizeFilesize = inject(HumanizeFilesizePipe);

    public async getDocuments(refId: string, partnerId: string): Promise<DocumentDto[]> {
        const response = await firstValueFrom(
            this.http.get<(DocumentDto & { name: string; extension: string })[]>(`${this.config.baseUrl}files/GetAll`, {
                params: {
                    refId: refId,
                    partnerId: partnerId,
                },
            }),
        );

        return (response ?? []).map(mapDocumentDto);
    }

    public async checkIfDocumentsExists(refId: string, partnerId: string, fileName: string): Promise<boolean> {
        return await firstValueFrom(
            this.http.get<boolean>(`${this.config.baseUrl}files/CheckIfExists`, {
                params: {
                    refId: refId,
                    partnerId: partnerId,
                    fileName: encodeURIComponent(fileName),
                },
            }),
        );
    }

    private async uploadDocument(
        refId: string,
        partnerId: string,
        creator: string,
        fileName: string,
        file: File,
    ): Promise<DocumentDto> {
        const { name, extension } = getFileParts(fileName);
        const result = await readFileAsBase64(file);
        const base64 = result.split(';base64,')[1];

        const dto = await firstValueFrom(
            this.http.post<DocumentDto & { name: string; extension: string }>(`${this.config.baseUrl}files/Upload`, {
                content: base64,
                contentType: file.type,
                creator: creator,
                name: name,
                extension: extension,
                size: file.size,
                refId: refId,
                partnerId: partnerId,
            }),
        );

        return mapDocumentDto(dto);
    }

    public upload(
        refId: string,
        partnerId: string,
        file: File,
        creator: string,
        nameStrategy?: FileUploadNameStrategy,
    ): Observable<FileUploadStatus<DocumentDto>> {
        return findUniqueFileName({
            fileName: file.name,
            nameStrategy: nameStrategy,
            checkIfExists: (fileName: string) => this.checkIfDocumentsExists(refId, partnerId, fileName),
        }).pipe(
            mergeMap(({ exists, fileName }) => {
                const maxSize = megabyte(50);
                if (file.size > maxSize) {
                    return of<FileUploadStatus<DocumentDto>>({
                        status: 'failed',
                        error: this.translate.instant('fileUpload.fileTooLarge', {
                            maxSize: this.humanizeFilesize.transform(maxSize),
                        }),
                    });
                }

                if (exists && nameStrategy !== 'replace') {
                    return of<FileUploadStatus<DocumentDto>>({ status: 'file-exists' });
                }

                return from(this.uploadDocument(refId, partnerId, creator, fileName, file)).pipe(
                    map<DocumentDto, FileUploadStatus<DocumentDto>>((doc) => ({ status: 'completed', result: doc })),
                    startWith<FileUploadStatus<DocumentDto>>({
                        status: 'uploading',
                        label: this.translate.instant('fileUpload.uploading'),
                    }),
                );
            }),
            startWith<FileUploadStatus<DocumentDto>>({
                status: 'uploading',
                label: this.translate.instant('fileUpload.checkFileName'),
            }),
        );
    }

    public async download(document: DocumentDto): Promise<DocumentContentDto> {
        const fileData = await firstValueFrom(
            this.http.get<{ content: string; contentType: string }>(`${this.config.baseUrl}files/Download`, {
                params: {
                    refId: document.refId,
                    partnerId: document.partnerId,
                    fileName: encodeURIComponent(document.fullName),
                },
            }),
        );

        return {
            name: document.fullName,
            content: fileData.content,
            contentType: fileData.contentType,
        };
    }

    public async remove(options: { refId: string; partnerId: string; fileName: string }): Promise<void> {
        await firstValueFrom(
            this.http.delete<void>(`${this.config.baseUrl}files/Delete`, {
                params: {
                    refId: options.refId,
                    partnerId: options.partnerId,
                    fileName: encodeURIComponent(options.fileName),
                },
            }),
        );
    }

    public async rename(document: DocumentDto, newName: string): Promise<DocumentDto> {
        const { name, extension } = getFileParts(document.fullName);
        const oldName = name.includes('%20') ? encodeURI(name) : name;

        await firstValueFrom(
            this.http.put<void>(
                `${this.config.baseUrl}files/Rename`,
                {},
                {
                    params: {
                        refId: document.refId,
                        partnerId: document.partnerId,
                        oldName: encodeURIComponent(`${oldName}.${extension}`),
                        newName: encodeURIComponent(decodeURI(newName)),
                    },
                },
            ),
        );

        return {
            ...document,
            fullName: newName,
        };
    }
}
