import { inject, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Guid } from '../utils/guid';

@Pipe({
    name: 'creator',
    standalone: true,
})
export class CreatorPipe implements PipeTransform {
    private readonly translate = inject(TranslateService);

    public transform(value: string): string {
        return Guid.isValid(value) ? this.translate.instant('partnerId.' + value) : value;
    }
}
