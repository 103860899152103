import { Directive, inject, Input, OnChanges, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, combineLatest, map, Subject, takeUntil } from 'rxjs';
import { Permission } from '../../models/roles.model';
import { ProductTitle } from '../models/product-titles';
import { RolesService } from '../services/roles.service';

@Directive({
    selector: '[hasPermission]',
    standalone: true,
})
export class HasPermissionDirective implements OnInit, OnChanges {
    private readonly templateRef = inject<TemplateRef<unknown>>(TemplateRef);
    private readonly viewContainer = inject(ViewContainerRef);
    private readonly rolesService = inject(RolesService);

    @Input() public hasPermission: Permission;
    @Input() public hasPermissionPartner: string;
    @Input() public hasPermissionProduct: ProductTitle;
    #destroy = new Subject<void>();
    #updateView = new BehaviorSubject<void>(null);

    public ngOnInit() {
        combineLatest({
            roles: this.rolesService.isGranted(
                this.hasPermission,
                this.hasPermissionProduct,
                this.hasPermissionPartner,
            ),
            update: this.#updateView.asObservable(),
        })
            .pipe(
                map(({ roles }) => roles),
                takeUntil(this.#destroy),
            )
            .subscribe((isGranted) => {
                if (this.viewContainer) {
                    this.viewContainer.clear();
                }
                if (isGranted) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                }
            });
    }

    public ngOnChanges() {
        this.#updateView.next();
    }
}
