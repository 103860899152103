import { NgIf } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { Address } from '../../models/address.model';
import { Configuration } from '../../models/configuration.model';
import { Restrictions } from '../../models/contract-type-restrictions.model';
import { GENDERS } from '../../models/gender.model';
import { Person } from '../../models/person.model';
import { Permission } from '../../models/roles.model';
import { HasPermissionDirective } from '../../permissions/directives/has-permission.directive';
import { ProductTitle } from '../../permissions/models/product-titles';
import { GenderPipe } from '../../pipes/gender.pipe';
import { checkSetValidBirthdateByNationalIdentificationNumber, getDateUtc, isValidJMBG } from '../../utils/date';
import { SharedPersonAgeIconComponent } from '../person-age-icon/person-age-icon.component';

@Component({
    selector: 'shared-person-editor',
    standalone: true,
    imports: [
        NgIf,
        FormsModule,
        TranslatePipe,
        ButtonModule,
        TooltipModule,
        DropdownModule,
        InputTextModule,
        CalendarModule,
        HasPermissionDirective,
        GenderPipe,
        SharedPersonAgeIconComponent,
    ],
    templateUrl: './person-editor.component.html',
})
export class PersonEditorComponent implements OnInit {
    private readonly configuration = inject(Configuration);

    @Input() public person: Person = { address: {} as Address };
    @Input() public showPassport = false;
    @Input() public showAddress = true;
    @Input() public showContacts = true;
    @Input() public isBirthDateRequired = true;
    @Input() public isAdding = false;
    @Input() public isEditing = false;
    @Input() public isAdable = true;
    @Input() public restrictPartner: string;
    @Input() public restrictPermission: Permission;
    @Input() public restrictProduct: ProductTitle;
    @Input({ required: true }) public restrictions: Restrictions | null;
    @Input({ required: true }) public partnerId: string;
    @Output() public personChanged = new EventEmitter<Person>();

    protected personEditCopy: Person | undefined;
    protected salutations = GENDERS;
    protected currentDate = new Date();
    protected JMBGIsValid = true;

    public ngOnInit() {
        this.isEditing = this.isAdding;

        this.person = {
            ...this.person,
            address:
                this.person.address && this.person.address != null
                    ? this.person.address
                    : { addressLine1: '', city: '', country: '', postalCode: '', addressLine2: '' },
            dateOfBirth: this.person.dateOfBirth ? new Date(this.person.dateOfBirth) : undefined,
        };
    }

    protected saveEdit() {
        if (this.#checkBirthDateValidation() && this.person.firstName && this.person.lastName && this.JMBGIsValid) {
            this.isEditing = false;
            this.personEditCopy = undefined;

            if (this.person.dateOfBirth) {
                this.person.dateOfBirth = getDateUtc(this.person.dateOfBirth);
            }

            this.personChanged.emit(this.person);
        }
    }

    protected checkSetValidBirthdate(nationalIdentificationNumber: string) {
        if (this.configuration.partnerIds.a1_srb?.toLowerCase() === this.partnerId.toLowerCase()) {
            if (isValidJMBG(nationalIdentificationNumber)) {
                this.JMBGIsValid = true;
                this.person.nationalIdentificationNumber = nationalIdentificationNumber;
                const calculatedBirthdate =
                    checkSetValidBirthdateByNationalIdentificationNumber(nationalIdentificationNumber);
                if (calculatedBirthdate !== null) {
                    this.person.dateOfBirth = calculatedBirthdate;
                }
            } else {
                this.JMBGIsValid = false;
            }
        } else {
            this.JMBGIsValid = true;
            this.person.nationalIdentificationNumber = nationalIdentificationNumber;
        }
    }

    #checkBirthDateValidation(): boolean {
        return this.isBirthDateRequired ? this.person.dateOfBirth != null : true;
    }

    protected cancelEdit() {
        this.isEditing = false;
        this.person = this.personEditCopy ?? {};
        this.personEditCopy = undefined;
    }

    protected edit() {
        this.isEditing = true;
        this.personEditCopy = { ...this.person };
    }

    public resetView() {
        this.person = { address: {} as Address };
        this.isEditing = true;
    }

    public setEditing() {
        this.isEditing = true;
    }
}
