import { Component, inject } from '@angular/core';
import { CommonStateService } from '../../services/common-state.service';

@Component({
    selector: 'shared-product-icon',
    templateUrl: './product-icon.component.html',
})
export class ProductIconComponent {
    private readonly commonStateService = inject(CommonStateService);

    protected productCategory$ = this.commonStateService.productCategory$;
}
