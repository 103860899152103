import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { Observable, shareReplay } from 'rxjs';
import { ProductCategory, SubProductCategory } from '../models/product-category.model';
import { State } from '../models/state.model';
import { DREI_TRAVEL_TABS } from '../models/tabs/drei-travel-tabs.model';
import { HANDHELD_TABS } from '../models/tabs/handheld-tabs.model';
import { ORGANIZATION_TABS } from '../models/tabs/orginzation-tabs.model';
import { TRAVEL_TABS } from '../models/tabs/travel-tabs.model';

export class TabState {
    tabs: MenuItem[] = [{}];
}

const tabInitial: TabState = {
    tabs: [],
};

@Injectable({
    providedIn: 'root',
})
export class TabStateService extends State<TabState> {
    translationService = inject(TranslateService);

    tabs: MenuItem[];
    tabs$: Observable<MenuItem[]> = this.select((state) => state.tabs).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    /**
     *
     */
    constructor() {
        super(tabInitial);
    }

    public setTabs(tabs: MenuItem[]) {
        this.setState({
            ...this.state,
            tabs,
        });
    }

    public initTabs(productCategory: ProductCategory, subProductCatgeory?: SubProductCategory) {
        let tabs: MenuItem[] = [];
        switch (productCategory) {
            case ProductCategory.TRAVEL_INSURANCE:
                if (subProductCatgeory === SubProductCategory.ORGANIZATION) {
                    tabs = ORGANIZATION_TABS;
                    break;
                } else {
                    tabs = TRAVEL_TABS;
                    break;
                }
            case ProductCategory.MASTERCARD:
                tabs = HANDHELD_TABS;
                break;
            case ProductCategory.TRAVEL_INSURANCE_DREI:
                tabs = DREI_TRAVEL_TABS;
                break;
        }

        this.setTabs(
            tabs.map((tab) => {
                return {
                    label: this.translationService.instant(tab.label),
                    routerLink: tab.routerLink,
                };
            }),
        );
    }

    public getTabs(): MenuItem[] {
        return this.state.tabs;
    }
}
