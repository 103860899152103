import { DateTime } from 'luxon';
import { AcceptanceState, InvoiceState } from '../enums';
import { FileReferenceDto } from './file-reference.dto';
import { MoneyDto } from './money.dto';

export interface InvoiceDetailsDto {
    id: string;
    invoiceNumber: string;
    state: InvoiceState;
    acceptanceState: AcceptanceState | null;
    invoiceOn: DateTime;
    createdOn: DateTime;
    createdBy: string;
    modifiedOn: DateTime | null;
    modifiedBy: string | null;
    category: string;
    supplier: string | null;
    initiator: string;
    invoiced: MoneyDto;
    invoicedConverted: MoneyDto;
    claimed: MoneyDto;
    claimedConverted: MoneyDto;
    paid: MoneyDto | null;
    accepted: MoneyDto | null;
    file: FileReferenceDto | null;
    name: string;
    description: string | null;
    hasReceivedOriginalDocuments: boolean;
    customerConfirmationExplanation: string | null;
}

export function mapInvoiceDetailsDto(dto: InvoiceDetailsDto): InvoiceDetailsDto {
    return {
        ...dto,
        invoiceOn: DateTime.fromISO(dto.invoiceOn as never as string),
        createdOn: DateTime.fromISO(dto.createdOn as never as string),
        modifiedOn: dto.modifiedOn != null ? DateTime.fromISO(dto.modifiedOn as never as string) : null,
    };
}
