import { DateTime } from 'luxon';

export interface NoteDto {
    id: string;
    creator: string;
    subject: string;
    body: string;
    timestamp: DateTime;
}

export function mapNoteDto(dto: NoteDto): NoteDto {
    return {
        ...dto,
        timestamp: DateTime.fromISO(dto.timestamp as never as string),
    };
}
