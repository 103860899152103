import { ROLES, Roles } from './roles.model';

export class Configuration {
    baseUrl: string;
    bffBaseUrl: string;
    subscriptionKey: string;
    production: boolean;
    partnerIds: PartnerIds;
    keycloak: KeyCloakConfiguration;
    logging: boolean;
}

export interface KeyCloakConfiguration {
    enabled: boolean;
    client: {
        realm: string;
        url: string;
        clientId: string;
        keycloakOnLoad: 'login-required' | 'check-sso';
        flow: 'standard' | 'implicit' | 'hybrid';
        checkLoginIframe: boolean;
        enableBearerInterceptor: boolean;
    };
    roles: Roles;
}

export const KEYCLOAK_CONFIGURATION_DEVELOPMENT: KeyCloakConfiguration = {
    enabled: true,
    client: {
        realm: 'Core',
        url: 'https://dev.lamie.systems/auth/',
        clientId: 'core_dev_client',
        keycloakOnLoad: 'login-required',
        flow: 'standard',
        checkLoginIframe: false,
        enableBearerInterceptor: true,
    },
    roles: ROLES,
};

export const KEYCLOAK_CONFIGURATION_STAGING: KeyCloakConfiguration = {
    enabled: true,
    client: {
        realm: 'core',
        url: 'https://staging.lamie.systems/auth/',
        clientId: 'core_staging_client',
        keycloakOnLoad: 'login-required',
        flow: 'standard',
        checkLoginIframe: false,
        enableBearerInterceptor: true,
    },
    roles: ROLES,
};

export const KEYCLOAK_CONFIGURATION_PRODUCTION: KeyCloakConfiguration = {
    enabled: true,
    client: {
        realm: 'Core',
        url: 'https://lamie.systems/auth/',
        clientId: 'core_prod_client',
        keycloakOnLoad: 'login-required',
        flow: 'standard',
        checkLoginIframe: false,
        enableBearerInterceptor: true,
    },
    roles: ROLES,
};

export interface PartnerIds {
    a1_aut: string;
    a1_srb: string;
    a1_hrv: string;
    a1_bgr: string;
    a1_svn: string;
    h3a_aut: string;
}

export const PARTNER_IDS_DEVELOPMENT: PartnerIds = {
    a1_aut: '2BFB5089-11A8-E711-80DA-00155D257B58'.toLowerCase(),
    a1_srb: '734DFE80-11A8-E711-80DA-00155D257B58'.toLowerCase(),
    a1_hrv: 'B025621B-30B1-EA11-8100-00155D257A4E'.toLowerCase(),
    a1_bgr: 'DC47BA25-5CC5-E711-80DB-00155D257B58'.toLowerCase(),
    a1_svn: '56E956C4-EC4E-E911-80EF-00155D257FCF'.toLowerCase(),
    h3a_aut: 'E78D1833-5A9E-E911-80F2-00155D257FCF'.toLowerCase(),
};

export const PARTNER_IDS_STAGING: PartnerIds = {
    a1_aut: 'F1819C67-3ED0-E711-80DD-00155DD477BF'.toLowerCase(),
    a1_srb: 'FA819C67-3ED0-E711-80DD-00155DD477BF'.toLowerCase(),
    a1_hrv: '977B03C4-30B1-EA11-8100-00155D257A4E'.toLowerCase(),
    a1_bgr: 'CB77C280-51D0-E711-80DD-00155DD477BF'.toLowerCase(),
    a1_svn: '134D3786-ED4E-E911-80EF-00155D257FCF'.toLowerCase(),
    h3a_aut: '7233CEB3-2E96-E811-80E7-00155DD48140'.toLowerCase(),
};

export const PARTNER_IDS_PRODUCTION: PartnerIds = {
    a1_aut: 'DAB293EA-5CD0-E711-80D7-00155DD475CE'.toLowerCase(),
    a1_srb: 'E0B293EA-5CD0-E711-80D7-00155DD475CE'.toLowerCase(),
    a1_hrv: '56A4070C-31B1-EA11-80FD-00155DD47870'.toLowerCase(),
    a1_bgr: '22258D1B-5DD0-E711-80D7-00155DD475CE'.toLowerCase(),
    a1_svn: '63C5AAB0-ED4E-E911-80E7-00155DD4829B'.toLowerCase(),
    h3a_aut: '7B612CD1-9DB2-E911-80EC-00155DD4798D'.toLowerCase(),
};
