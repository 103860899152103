import { inject, Injectable } from '@angular/core';
import { Configuration, HttpClientAdapter } from '@luis/common/shared';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { firstValueFrom } from 'rxjs';
import { CreateNoteDto, mapNoteDto, NoteDto, QuickNoteDto } from '../../dtos';

@Injectable({
    providedIn: 'root',
})
export class NoteApiService {
    private readonly http = inject(HttpClientAdapter);
    private readonly config = inject(Configuration);
    private readonly translate = inject(TranslateService);

    public async getNotes(refId: string, refName: string): Promise<NoteDto[]> {
        const notes = await firstValueFrom(
            this.http.get<NoteDto[]>(`${this.config.baseUrl}Notes/GetAllByRefId`, {
                params: {
                    refId: refId,
                    refName: refName,
                },
            }),
        );

        return notes.map(mapNoteDto);
    }

    public async getQuickNotes(): Promise<QuickNoteDto[]> {
        return [
            {
                color: 'yellow',
                label: this.translate.instant('notes.autofill.additionalinsured.chiptitle'),
                subject: this.translate.instant('notes.autofill.additionalinsured.subject'),
                body: this.translate.instant('notes.autofill.additionalinsured.body'),
            },
            {
                color: 'green',
                label: this.translate.instant('notes.autofill.maininusured.chiptitle'),
                subject: this.translate.instant('notes.autofill.maininusured.subject'),
                body: this.translate.instant('notes.autofill.maininusured.body'),
            },
            {
                color: 'orange',
                label: this.translate.instant('notes.autofill.customerdata.chiptitle'),
                subject: this.translate.instant('notes.autofill.customerdata.subject'),
                body: this.translate.instant('notes.autofill.customerdata.body'),
            },
            {
                color: 'orange',
                label: this.translate.instant('notes.autofill.changecover.chiptitle'),
                subject: this.translate.instant('notes.autofill.changecover.subject'),
                body: this.translate.instant('notes.autofill.changecover.body'),
            },
            {
                color: 'red',
                label: this.translate.instant('notes.autofill.cancellation.chiptitle'),
                subject: this.translate.instant('notes.autofill.cancellation.subject'),
                body: this.translate.instant('notes.autofill.cancellation.body'),
            },
            {
                color: 'purple',
                label: this.translate.instant('notes.autofill.claim.creation.chiptitle'),
                subject: this.translate.instant('notes.autofill.claim.creation.subject'),
                body: this.translate.instant('notes.autofill.claim.creation.body'),
            },
            {
                color: 'purple',
                label: this.translate.instant('notes.autofill.claim.statusupdate.chiptitle'),
                subject: this.translate.instant('notes.autofill.claim.statusupdate.subject'),
                body: this.translate.instant('notes.autofill.claim.statusupdate.body'),
            },
            {
                color: 'purple',
                label: this.translate.instant('notes.autofill.claim.dailyreceipt.chiptitle'),
                subject: this.translate.instant('notes.autofill.claim.dailyreceipt.subject'),
                body: this.translate.instant('notes.autofill.claim.dailyreceipt.body'),
            },
        ];
    }

    public async create(note: CreateNoteDto): Promise<string> {
        return await firstValueFrom(
            this.http.post<string>(`${this.config.baseUrl}Notes/Create`, {
                ...note,
                timestamp: DateTime.utc(),
                type: 'note',
            }),
        );
    }

    public async remove(noteId: string): Promise<void> {
        await firstValueFrom(
            this.http.delete<void>(`${this.config.baseUrl}Notes/Delete`, {
                params: {
                    noteId: noteId,
                },
            }),
        );
    }
}
