import { computed, signal, WritableSignal } from '@angular/core';
import { Guid } from 'guid-typescript';
import { Observable, Subject, takeUntil } from 'rxjs';
import { FileUploadNameStrategy } from './file-upload-name-strategy.type';
import { FileUploadOptions } from './file-upload-options.type';
import { FileUploadStatus } from './file-upload-status.type';

export class FileUpload<T> {
    private readonly _file: WritableSignal<File>;
    private readonly _status: WritableSignal<FileUploadStatus<T>>;

    public readonly name = computed<string>(() => this._file().name);
    public readonly size = computed<number>(() => this._file().size);
    public readonly status = computed(() => this._status()?.status);
    public readonly label = computed<string | null>(() => {
        const status = this._status();
        if (status?.status === 'uploading') {
            return status.label ?? null;
        }
        return null;
    });
    public readonly progress = computed<number | null>(() => {
        const status = this._status();
        if (status?.status === 'uploading') {
            return status.progress ?? null;
        }
        return null;
    });
    public readonly error = computed<string | null>(() => {
        const status = this._status();
        if (status?.status === 'failed') {
            return status.error ?? null;
        }
        return null;
    });

    private cancel$ = new Subject<void>();
    private readonly handleUpload: (options: FileUploadOptions) => Observable<FileUploadStatus<T>>;
    private readonly handleCancel: () => void;
    private readonly handleComplete: (result: T) => void;

    constructor(options: {
        file: File;
        handleUpload: (options: FileUploadOptions) => Observable<FileUploadStatus<T>>;
        handleCancel: () => void;
        handleComplete: (result: T) => void;
    }) {
        this._file = signal(options.file);
        this._status = signal({ status: 'uploading' });

        this.handleUpload = options.handleUpload;
        this.handleCancel = options.handleCancel;
        this.handleComplete = options.handleComplete;
    }

    public start(options?: { nameStrategy?: FileUploadNameStrategy }) {
        this.cancel$.next();
        this.cancel$ = new Subject<void>();

        this.handleUpload({
            correlationId: Guid.create().toString(),
            file: this._file(),
            nameStrategy: options?.nameStrategy,
        })
            .pipe(takeUntil(this.cancel$))
            .subscribe({
                next: (update) => this._status.set(update),
                error: (err) => {
                    console.error(err);
                    this._status.set({ status: 'failed' });
                },
                complete: () => {
                    const status = this._status();
                    if (status != null && status.status === 'completed') {
                        this.handleComplete(status.result);
                    }
                },
            });
    }

    public cancel() {
        this.cancel$.next();
        this.handleCancel();
    }

    public copy() {
        this.start({ nameStrategy: 'rename' });
    }

    public replace() {
        this.start({ nameStrategy: 'replace' });
    }
}
